import React, { useState } from 'react';
import './seletiveprocess.css'; // Importe o arquivo CSS para estilização do dropdown
import { useTranslation } from 'react-i18next';

const FocusBtn = (props) => {
  const { t } = useTranslation()

  return (
    <div className="dropdown">
      <button className="dropdown-toggle btn-black" >
        {props.name}
      </button>

    </div>
  );
};

export default FocusBtn;
