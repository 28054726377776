import './Metodology.css'
import { useTranslation } from 'react-i18next';


const Store = () => {
    const { t } = useTranslation()
    return (

        <section id='metodologia' className="metodology-main">
            <div className='first-metodology-content'>
                <div>
                    <img src='/image/produtos_loja.png' alt="Imagem de aluno do Instituto Joga Junto sentado segurando um copo e olhando para o lado"></img>
                </div>
                <div>
                    <h2>{t("store-h2-1")} </h2>
                    <p>
                    {t("store-p-1")}
                    </p>
                    <p>
                    {t("store-p-2")}
                    </p>
                    <p>
                    {t("store-p-3")}
                    </p>

                    <a href="https://loja.jogajuntoinstituto.org/" target="_blank" rel="noopener noreferrer" className="store-button">
        {t("store-button-text")}
    </a>
                </div>


            </div>
            <div className='border-yellow'>
                <div className='second-metodology-content'>
                    {/* <UpRightCorner link="/image/corner_3.png"/> */}

  

            </div>
        </div>

        <div className='partner-joinus'>
                <h2>
                {t("partner-h2")}
                </h2>
            </div>
        </section >
    )
}

export default Store