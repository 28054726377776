
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import ModalComponent from './components/ModalComponent';
import NavBar from './components/Navbar';
import { PageCompany } from './PageCompany';
import PageIndex from './PageIndex';
import { PagePerson } from './PagePerson';
import { PageTeam } from './PageTeam';
import {useEffect, useState} from 'react'




function App() {
  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    const storedVisit = localStorage.getItem('firstVisit');
    if (storedVisit) {
      setFirstVisit(false);
    } else {
      localStorage.setItem('firstVisit', true);
    }
  }, []);
  
    
    return (
    <BrowserRouter>
    <div>
       <ModalComponent />
      
    </div>
    <NavBar></NavBar>
    <LanguageSwitcher/>
    <Routes>
      <Route path='/' element={<PageIndex/>}></Route>
      <Route path='/voluntaryperson' element={<PagePerson/>}></Route>
      <Route path='/company' element={<PageCompany/>}></Route>
      <Route path='/team' element={<PageTeam/>}></Route>
    </Routes>
    </BrowserRouter>

    
  );
}

export default App;
