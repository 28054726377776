import React from 'react';
import Dropdown from './Dropdown';
import './NavMenu.css';
import { useTranslation } from 'react-i18next';
import SeletiveProcess from './SeletiveProcess';
import FocusBtn from './FocusBtn';


const NavMenu = (props) => {
  const { t } = useTranslation();

  const dropitems = [
    { label: t('team'), link: '/team' },
  ];
  const selectiveProcess = [
    { label: 'Ilhabela', link: 'https://www.ilhabelatech.com/' },
    { label: 'Comunidade', link: 'https://processoseletivo.jogajuntoinstituto.org	' },
  ];

  return (
    <nav className="navmenu">
      <ul>
        {props.items.map((item) => (
          <li key={item.label}>
            <a href={item.link}>{item.label}</a>
          </li>
        ))}

        <li>
          <Dropdown name={t('transparency')} items={dropitems} />
          
        </li>
        <li>
          <FocusBtn name={t('store')}></FocusBtn>
        </li>
        {/* <li>
          <SeletiveProcess name={t('seletive-process')} items={selectiveProcess}></SeletiveProcess>
        </li> */}
      </ul>
    </nav>
  );
};

export default NavMenu;
